/* CSS Document */
/* MAIN CSS FOR TRANSPORT+ */

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap');



:root {

	--ff: 'Lato', sans-serif;
	--ff-fa: 'FontAwesome';

	--fw-r: 400;
	--fw-b: 900;

	--fz-p: 14px;
	--fz-ttl: 20px;

	--ss-px: 6px;
	--s-px: 8px;
	--m-px: 16px;
	--b-px: 24px;

	--brand-clr: #2FA2FA;
	--mfont-clr: #262626;
	--white-clr: #ffffff;
	--lines-clr: #C3C3C3;
	--menubg-clr:  #333441;
	--mrows-clr: #D5E1EB;
	--secrows-clr: #E6E6E6;
	--red-clr:  #CB1010;
	--redBgBtn-clr: #FCF0F0;
	--mb-hov-clr: #1C82D0;
	--lightGrayTxt-clr: #838383;
	--inactiveTxt-clr: #AAAAAA;
	--rofield-clr: #EBEBEB;
	--secBtn-clr: #F0F6FA;

	--excel-icn: #11AC0A;
	--thead-clr: #A8C6DE;
	--rowsBg-clr: #F4F4F4;
	--rowsHov-clr: #E2EDF5;
}


/*---- GENERAL ----*/

 
*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	min-width: 1420px;
    background: #ffffff;
	overflow-x: auto;
	color: var(--mfont-clr);
}
body, html {
	font-family: var(--ff);
	font-weight: var(--fw-r);
	color: var(--mfont-clr);
	font-size: var(--fz-p);
}
a {
	text-decoration: none;
}
img {
	border-style: none;
}
h1 {
	font-size: var(--fz-ttl);
}
button {
	font-family: var(--ff);
	font-weight: var(--fw-r);
	font-size: var(--fz-p);
	padding: var(--ss-px);
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.boton span {
	position: relative;
	padding-left: calc(var(--m-px) + 6px);
}
button span::before {
	font-size: var(--fz-p);
	font-family: var(--ff-fa);
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
    left: 2px;
}
input, 
select, 
textarea {
	border: 1px solid var(--lines-clr);
	border-radius: 5px;
	font-family: var(--ff);
}
input, 
textarea {
	padding: 3px var(--s-px);
}
select {
	/*padding: calc(var(--s-px) / 4) var(--s-px);*/
	border: 1px solid var(--lines-clr);
	border-radius: 5px;
	font-family: var(--ff);
	padding: 4px var(--s-px);
}
input:read-only {
	background-color: var(--rofield-clr);
	color: var(--lightGrayTxt-clr);
}
::placeholder {
	color: var(--inactiveTxt-clr);
	font-family: var(--ff);
}

.buttnAnim {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.primBtn {
	background-color: var(--brand-clr);
	border: none;
	color: var(--white-clr);
}
.secBtn {
	background-color: var(--secBtn-clr);
	border: 1px solid var(--brand-clr);
	color: var(--brand-clr);
}
.redBtn {
	background-color: var(--redBgBtn-clr);
	border: 1px solid var(--red-clr);
	color: var(--red-clr);
}
.add span::before {
	content: '\f055';
}
.time span::before {
	content: '\f017';
}
.save span::before {
	content: '\f0c7';
}
.documento span::before {
	content: '\f15c';
}
.wp span::before {
	content: '\f232';
}
.erase span::before {
	content: '\f057';
}
.erasebtn {
	content: '\f057';
}


.plus-button {
	background-color: var(--white-clr);
	border: none;
	height: 20px;
	width: 20px;
	margin-right: calc(var(--s-px) / 4);
	border-radius: 50px;
	position: relative;
}
.plus-button::after, 
.plus-button::before {
	content: "";
	display: block;
	background-color: var(--brand-clr);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.plus-button::before {
	height: 10px;
	width: 2px;
}
.plus-button::after {
	height: 2px;
	width: 10px;
}

.minus-button {
	content: "";
	background-color: var(--white-clr);
	border: none;
	height: 20px;
	width: 20px;
	margin-right: calc(var(--s-px) / 4);
	border-radius: 50px;
	position: relative;
}
.minus-button::after, 
.minus-button::before {
	content: "";
	display: block;
	background-color: var(--red-clr);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.minus-button::before {
	height: 2px;
	width: 10px;
}
.minus-button::after {
	height: 2px;
	width: 10px;
}



.ms-right {
	margin-right: var(--s-px);
}
.ms-left {
	margin-left: var(--s-px);
}







/*---- MAIN CSS ----*/

header {
	display: flex;
}
.logo {
	width: 200px;
	min-width: 200px;
}
.logo img {
	width: 100%;
	display: block;
}
.mainButtons {
	width: 100%;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--lines-clr);
	border-top: 1px solid var(--lines-clr);
}
.burger {
	cursor: pointer;
	padding: var(--s-px) var(--m-px);
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-right: 1px solid var(--lines-clr);
}
.burger div {
	width: 25px;
	height: 3px;
	margin: calc(var(--s-px) - 5px);
	background-color: var(--mfont-clr);
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.logOutBtn {
	display: flex;
	padding: var(--s-px) var(--m-px);
	border-left: 1px solid var(--lines-clr);
	align-items: center;
}
.logOutIcn {
	width: 15px;
	height: 15px;
	position: relative;
}
.logOutIcn img {
	width: 100%;
	height: auto;
	position: absolute;
}
.wdoor {
	opacity: 0;
	-webkit-transition: opacity 0.3s ease;
	-moz-transition: opacity 0.3s ease;
	-ms-transition: opacity 0.3s ease;
	transition: opacity 0.3s ease;
}
.bdoor {
	-webkit-transition: opacity 0.3s ease;
	-moz-transition: opacity 0.3s ease;
	-ms-transition: opacity 0.3s ease;
	transition: opacity 0.3s ease;
}
.logOutBtn p {
	padding-left: calc(var(--s-px) - 4px);
}


main {
	display: flex;
}


.mainMenu {
	width: 200px;
	min-width: 200px;
	background-color: var(--menubg-clr);
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.mainMenu p {
	color: var(--white-clr);
}
.mainMenu a {
	display: block;
	padding: calc(var(--s-px) + 2px) calc(var(--m-px) + 4px);
	border-left: 2px solid var(--menubg-clr);
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.menuLink {
	display: flex;
	align-items: center;
}
.menuIcon {
	width: calc(var(--b-px) - 4px);
	min-width: calc(var(--b-px) - 4px);
	margin-right: var(--m-px);
}
.menuIcon img {
	width: 100%;
	height: auto;
}
.menuItem p {
	white-space: nowrap;
}

.hide {
	width: 70px!important;
	min-width: 70px!important;
}
.hide > a > .menuLink > .menuItem {
	display: none;
}

.wrapper {
	width: 100%;
}




.sectionTittle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid var(--lines-clr);
	padding: var(--s-px) var(--m-px);
}

.menuTabs {
	border-bottom: 1px solid var(--lines-clr);
	display: flex;
	padding: 0 var(--s-px);
}
.menuTabs a {
	display: block;
	color: var(--lightGrayTxt-clr);
	padding: var(--m-px);
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.menuTabs .active {
	color: var(--mfont-clr);
	font-weight: var(--fw-b);
	position: relative;
}
.menuTabs .active::after {
	content: '';
	width: 80%;
	display: block;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	bottom: 0;
	border: solid 1px var(--brand-clr);
}

.contentBox {
	padding: 0 var(--m-px);
}

.toggleBox {
	display: flex;
	align-items: center;
	padding: var(--m-px) 0;
}
.switch {
	position: relative;
	display: inline-block;
	width: 44px;
	height: 24px;
	margin: 0 var(--m-px);
}

.switch input { 
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--brand-clr);
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 18px;
	width: 18px;
	left: 3px;
	bottom: 3px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked + .slider {
	background-color: var(--brand-clr);
}

input:focus + .slider {
	box-shadow: 0 0 1px var(--brand-clr);
}

input:checked + .slider:before {
	-webkit-transform: translateX(20px);
	-ms-transform: translateX(20px);
	transform: translateX(20px);
}


.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.progressBox {
	padding: var(--m-px) 0;
	position: relative;
}
.progressBar {
	counter-reset: step;
	display: flex;
	justify-content: space-between;
}
.progressBar li {
	list-style-type: none;
	color: var(--inactiveTxt-clr);
	position: relative;
	background-color: var(--white-clr);
	padding: 0 var(--m-px);
}
.progressBar li:before {
	width: 20px;
	height: 20px;
	content: counter(step);
	counter-increment: step;
	line-height: 20px;
	display: inline-block;
	text-align: center;
	border-radius: 50%;
	margin-right: var(--s-px);
	background-color: var(--inactiveTxt-clr);
	color: var(--white-clr);
}
.progressLine {
	width: 100%;
	height: 1px;
	background-color: var(--inactiveTxt-clr);
	position: absolute;
	top: 50%;
	z-index: -1;
}
.progressBar li.active {
	color: var(--mfont-clr);
	font-weight: var(--fw-b);
}
.progressBar li.active:before {
	background-color: var(--brand-clr);
}




.topControls{
	display: flex;
	padding: var(--m-px) 0;
}
.fieldContrl {
	margin: 0 calc(var(--s-px) / 2 );
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}
.fieldContrl:first-child {
	margin-left: 0;
}
.fieldContrl p {
	margin-bottom: calc(var(--s-px) / 2 );
}
.fieldContrl input {
	width: 100px;
}



.dataContainer {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.dataTable {
	border: 1px solid var(--lines-clr);
	margin-bottom: var(--m-px);
}
.threeCol {
	width: 32.5%;
}
.twoCol{
	width: 46.5%;
	margin-left: 2%;
}
.oneCol {
	width: 100%;
}
.oneColmargin {
	width: 95%;
	margin-left: 2%;
}
.dataTitle {
	background-color: var(--mrows-clr);
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.dataTitle p {
	font-weight: var(--fw-b);
	color: #36596b;
}
.dataTitle, 
.dataRow,
.singleRow {
	padding:  calc(var(--s-px) - 2px) var(--s-px);
}
.dataRow {
	display: flex;
	border-top: 1px solid var(--secrows-clr);
	justify-content: space-between;
	align-items: center;
}
.fieldInput {
	display: flex;
	width: 65%;
	justify-content: flex-end;
	align-items: center;
}
.fieldInput input[type="text"], 
.fieldInput select,
.fieldInput textarea {
	width: 90%;
}
.fieldInput input[type="number"] {
	width: 40%;
	margin-left: var(--s-px);
}
.radioOption {
	height: 26px;
	display: flex;
}
.radioOption input[type="radio"] {
	display: none;
}
.radioBox {
	padding: calc(var(--s-px) / 4) calc(var(--s-px) / 2);
	border: 1px solid var(--lines-clr);
	background-color: var(--white-clr);
	text-align: center;
	display: block;
	cursor: pointer;
	position: relative;
}
.radioOption input[type="radio"]:checked + .radioBox {
	background-color: var(--brand-clr);
	border: 1px solid var(--brand-clr);
}
.radioOption input[type="radio"]:checked + .radioBox span {
	color: var(--white-clr);
}
.fieldInput .currency {
	width: 40%;
	margin-left: var(--s-px);
	background-color: #C0EDBE;
}
.singleRow {
	display: flex;
}
.dataCol {
	margin: 0 calc(var(--s-px) / 2 );
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}
.dataCol:first-child {
	margin-left: 0;
}
.dataCol p {
	margin-bottom: calc(var(--s-px) / 2 );
}
.dataCol input, 
.dataCol select {
	width: 180px;
}
.dataTitleBtns {
	display: flex;
}









/*---- HOVERS ----*/


.buttnAnim:hover {
	background-color: var(--brand-clr);
	color: var(--white-clr);
}
.buttnAnim:hover > .bline {
	background-color: var(--white-clr);
}
.logOutBtn:hover > .logOutIcn >.bdoor {
	opacity: 0;
}
.logOutBtn:hover > .logOutIcn >.wdoor {
	opacity: 1;
}

.mainMenu a:hover {
	background-color: #1C1C24;
	border-left: 2px solid var(--brand-clr);
}

.primBtn:hover {
	background-color: var(--mb-hov-clr);
}

.secBtn:hover {
	background-color: var(--brand-clr);
	color: var(--white-clr);
}
.redBtn:hover {
	background-color: var(--red-clr);
	color: var(--white-clr);
}
.plus-button:hover {
	background-color: var(--secBtn-clr);
}

.menuTabs a:hover {
	color: var(--mfont-clr);
}




.excel span::before {
	content: '\f1c3';
	color: var(--excel-icn);
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
}


.tableWrapper {
	margin-top: var(--m-px);
	overflow-x: scroll;
	width: 85%;
}
.generalTable {
	border-collapse: collapse;
	border: 1px solid var(--lines-clr);
}
.generalTable thead tr {
	background-color: var(--thead-clr);
}
.generalTable th, 
.generalTable td {
	padding: var(--s-px);
	border: 1px solid var(--secrows-clr);
	min-width: 120px;
}
.generalTable tbody tr:nth-of-type(even) {
	background-color: var(--rowsBg-clr);
}
.generalTable th {
	text-align: left;
}
.tableDropdown {
	padding: 0;
	border: none;
	border-radius: 0;
	color: var(--white-clr);
	background: transparent;
	display: inline-block;
	float: right;
}
.t-center {
	text-align: center;
}
.accIcn {
	color: var(--brand-clr);
	cursor: pointer;
	margin: 0 calc(var(--s-px) / 2 );
}
.whatsIcn {
	color: #128C7E;
	cursor: pointer;
	margin: 0 calc(var(--s-px) / 2 );
}


/*---- Modulo viajes CSS añadido ----*/

.excel:hover span::before {
	color: var(--white-clr);
}
.generalTable tbody tr:hover {
	background-color: var(--rowsHov-clr);
}
.tableDropdown:hover {
	color: var(--rofield-clr);
}

/*---- CUSTOM ANTONIO ----*/
.makeStyles-input-8 {
    width: 90%;
     border: 0 !important;
    border-radius: 5px;
}

.selectbusqueda{
	width: 90%;
	/*padding: calc(var(--s-px) / 4) var(--s-px);*/
	
    font-family: var(--ff);
}

.selectbusqueda2{
	width: 90%;
	/*padding: calc(var(--s-px) / 4) var(--s-px);*/
	
    font-family: var(--ff);
}

.selectbusqueda > div >  div> input{
	padding: 2px 8px !important;
}
.selectbusqueda2 > div >  div> input{
	padding: 2px 0px !important;
}

.fieldInput > .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
	height: 26px !important;
	padding: 0 !important;
}

.reducedselect2 > .MuiInputBase-root > .MuiInputBase-input{ 
	padding: 2px 4px !important;
} 
.fieldInput > .react-datepicker-wrapper {
    width: 90%;
    padding: px var(--s-px);
}
.fieldInput > .react-datepicker-wrapper > .react-datepicker__input-container> input {
    width: 100%;
 
}

.MuiTypography-body2 {
    font-size: 1rem !important;
	font-family: var(--ff) !important;
	font-weight: var(--fw-r) !important;
    line-height: 1.43;
    letter-spacing: 0.01071em;
}
.fixedP{
	margin-top: 0;
	margin-bottom: 0;
}

.pro-sidebar > .pro-sidebar-inner {
    background: #333441 !important;
    height: 100%;
    position: relative;
    z-index: 101;
}

.react-daterange-picker, .react-daterange-picker *, .react-daterange-picker *:before, .react-daterange-picker *:after{
	border-radius: 5px !important;
}

.MuiTypography-body1 {
    font-size: 1rem;
    font-family: var(--ff) !important; 
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}
.handsontable thead th .relative {
    padding: 5px 4px;
}

::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 4px;
	height: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: #2FA2FA;
	box-shadow: 0 0 1px #2FA2FA;
  }