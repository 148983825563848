

//TIPOGRAFIA

/* FONTS */
/* latin-ext */
$font-family: 'Lato', sans-serif;
$font-weight-bold: 700;
$font-weight-regular: 100;
$font-weight-light: 300;

//COLORES

$accent-color-1: #36596b;
$accent-color-2: #262e47;
$accent-color-3: #8aa4af;
$accent-color-4: #374b85;
$textbuttons: white;

$color-special-1: #f2ca80;

$lightgrey: #f9f9f9;
$border-light: #efefef;

$gray: #2e2e2e;
$elegant-color-dark: #212121;
$success: #46be8a;