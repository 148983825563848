//tema para botones industrial theme
@import 'variables';

@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');

/*body {
    background: #A7A9AC;
    background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(.34, rgba(230,237,241,.05)),
        color-stop(.67, rgba(230,237,241,0)));
    -webkit-background-size: 5px 5px;
}

#pagegradient {
  background-image:
        -webkit-gradient(
            radial, 
            50% -50, 
            300, 
            50% 0, 
            0, 
            from(rgba(230, 237, 241, 0)), 
            to(rgba(230, 237, 241, 0.8)));
    
  height:100%;
  left:0px;
  position:absolute;
  top:0;
  width: 600px;
}*/
/*
.button {
    position: relative;
    top: 1px;
    left: 2px;
    display: inline-block;
    margin: 1vw;
    
    -webkit-border-radius: 10px;
    
    -webkit-box-shadow: 
        0px 3px rgba(128,128,128,1), 
        0px 4px rgba(118,118,118,1),
        0px 5px rgba(108,108,108,1),
        0px 6px rgba(98,98,98,1),
        0px 7px rgba(88,88,88,1),
        0px 8px rgba(78,78,78,1),
        0px 14px 6px -1px rgba(128,128,128,1);
    
    -webkit-transition: -webkit-box-shadow .1s ease-in-out;
} 

.button span {
    background-color: #E8E8E8;
    
    background-image: 
   
        -webkit-gradient(
            linear, 
            left bottom, 
            left top, 
            color-stop(50%,rgba(255,255,255,0)), 
            color-stop(50%,rgba(255,255,255,0.3)), 
            color-stop(100%,rgba(255,255,255,0.2))),
        

        -webkit-gradient(
            linear, 
            left top, 
            right top, 
            color-stop(0%,rgba(210,210,210,0.3)), 
            color-stop(20%,rgba(210,210,210,0)), 
            color-stop(80%,rgba(210,210,210,0)), 
            color-stop(100%,rgba(210,210,210,0.3))),
 
        -webkit-gradient(
            linear, 
            left top, 
            right top, 
            color-stop(0%,rgba(255,255,255,0)), 
            color-stop(20%,rgba(255,255,255,0.5)), 
            color-stop(80%,rgba(255,255,255,0.5)), 
            color-stop(100%,rgba(255,255,255,0))),        
/
        -webkit-gradient(
            linear, 
            0% 100%, 
            100% 0%, 
            color-stop(0%,rgba(255,255,255,0)), 
            color-stop(40%,rgba(255,255,255,0)), 
            color-stop(40%,#D2D2D1), 
            color-stop(60%,#D2D2D1), 
            color-stop(60%,rgba(255,255,255,0)), 
            color-stop(100%,rgba(255,255,255,0)));
    
        -webkit-box-shadow:
            0px -1px #fff,
            0px 1px 1px #FFFFFF; 
    
    -webkit-background-size: 100%, 100%, 100%, 4px 4px;
    
    -webkit-border-radius: 10px;
    -webkit-transition: -webkit-transform .1s ease-in-out;
    
    display: inline-block;
    padding: 10px 40px 10px 40px;
    
    color: #3A474D;
    text-transform: uppercase;
    font-family: 'TradeGothicLTStd-BdCn20','PT Sans Narrow';
    font-weight: 700;
    font-size: 20px;
    
    text-shadow: 0px 1px #fff, 0px -1px #262F33;
}

        .button span:hover {
            color: #AEBF3B;
            text-shadow: 0px -1px #97A63A;
            cursor: pointer;
        }

        .button:active {
            -webkit-box-shadow: 
                0px 3px rgba(128,128,128,1),
                0px 4px rgba(118,118,118,1),
                0px 5px rgba(108,108,108,1),
                0px 6px rgba(98,98,98,1),
                0px 7px rgba(88,88,88,1),
                0px 8px rgba(78,78,78,1),
                0px 10px 2px 0px rgba(128,128,128,.6); 
        }

        .button:active span{
            -webkit-transform: translate(0, 5px); 
        }

    .button span:after {
        //content: ">";
        display: block;
        width: 10px;
        height: 10px;
        
    
        position: absolute;
        right: 14px;
        top: 12px;    
        
        font-family: 'Cabin';
        font-weight: 700;
        color: #AEBF3B;
        text-shadow: 0px 1px #fff, 0px -1px #97A63A;
        font-size: 26px;
        
    }*/

    /*
    $base-color: #093355;
    $base-secundario: #386c81;
    $primarycosntrast:#ecc755;


    $base-color: #061f33;
    $base-secundario: #1b4252;
    $primarycosntrast:#f3d16b;
    $colorfondologo:#0f2d3a;

    
      $base-color: #030e4b;
    $base-secundario: #052e70;
    $primarycosntrast:#f3d16b;
    $colorfondologo:#0f233a;
    */

    $base-color: #8aa4af;
    $base-secundario: #051e34;
    $primarycosntrast:#f3d16b;
    $colorfondologo:#0f233a;

/*
    body{
       // background: $base-color !important;
    }
    .MuiBox-root {
      //  background: $base-color !important;
    }
    .MuiTabs-root{
        //background: $base-secundario  !important;
    }

    .MuiTab-textColorPrimary.Mui-selected {
       // color: $primarycosntrast  !important;
    }
    .PrivateTabIndicator-colorPrimary-3 {
    //    background-color: $primarycosntrast !important;
     //   color: $textbuttons;
    }
    .card-header{
      //  background-color: $base-secundario !important;
    }*/

    .MuiListItemIcon-root .fa, .MuiListItemText-root, .air__menuLeft__toggleButton .air__menuLeft__actionToggle,.dropdown-item .cursor-pointer{
        color: $textbuttons ;
    }

    .dropdown-item{
        padding: 0;
    }
    /*.dropdown-item, .dropdown-item i{
       // color:$primarycosntrast !important;
    }*/
    .air__menuLeft__outer{
        background-color: $base-secundario !important;
        background-attachment: fixed;
        background-color: var(--fire-color-sidenav-bg-default);
        background-image: url(//www.gstatic.com/mobilesdk/190424_mobilesdk/nav_nachos@2x.png);
        background-position: left 0 bottom 0;
        background-repeat: no-repeat;
        background-size: 256px 556px;
        display: flex;
        flex-direction: column;
        //height: 100vh;
        transition: width .3s cubic-bezier(0.4, 0, 0.2, 1);
        width: 256px;
    }
   /* .air__layout__header,.air__topbar{
       // background-color: $base-secundario !important;
    }
    .air__menuLeft__logo__name{
        background-color: $colorfondologo !important;
    }
    .air__footer__inner{
     //   color: $primarycosntrast;
    }*/
    .loginbackground{
        height: 100vh;
    }
    .cardlogin{
        margin-top: 10vh;
       // background-color: transparent ;
    }
    .loginbackground::after {
        content: "";
        background-image: url('../img/fondologin2.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

       // opacity: 0.5;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;   
        width: 100%;
        height: auto;
      }

      .MuiListItem-button{
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .25rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      }
      //$accent-color-2: #ffc107;

      .MuiListItem-button{
        background-color: $accent-color-2 ;
        border-radius: 5px !important;
        margin-top:5px !important;
        color: $textbuttons ;
      }
    table > tbody > tr:nth-child(even) > td {
        background-color: rgb(255, 255, 255);
    }
    
    table > tbody > tr:nth-child(odd) > td {
        background-color: rgba($color: #f4f4f4, $alpha: 0.5);
        
    }
    .tablenostriped > tbody > tr:nth-child(odd) > td {
        background-color: rgba($color: rgb(251, 250, 250), $alpha: 0);
        
    }
    
    .handsontable thead th{
        background-color: #a8c6de;
        color: black;
    }
    .pro-inner-item{
        font-size: large;
    }
    /*
    //navs como botones
     .pro-inner-item{
         margin-top: 5px;
         margin-left: 5px;
         margin-right: 5px;
         border-radius: 5px;
         background-color: $accent-color-2;
     }
     .pro-inner-item:hover{
        background-color: $accent-color-4;
    }
    /////
    */
    
    .iconfa{
        color: blue;
    }
    .headersidebar{
        height: 90px;
        padding-top: 10%;
    }
    .modal-lg{
        max-width: 1000px !important;
    }
    .btn-edit{
        background-color: #95d44c !important;
      }
     
    .handsontable tbody th.ht__active_highlight, .handsontable thead th.ht__active_highlight,.ht__highlight{
        background-color: #6480af !important;
    }
    .btn-outline-dark{
        background-color: #eceaea
    }
    .btn-outline-dark:hover{
        background-color: rgb(102, 129, 180);
    }
    /*.btn-outline-dark:active{
        background-color: rgb(149, 149, 149);
    }*/


    .pro-sidebar .pro-menu.square .iconcostos > .pro-inner-item > .pro-icon-wrapper
    {
        background-color:  rgb(248, 86, 86);
        color:black;
    }
    .pro-sidebar .pro-menu.square .iconingresos > .pro-inner-item > .pro-icon-wrapper
    {
        background-color:  rgb(152, 224, 129);
        color:black;
    }
    .MuiOutlinedInput-adornedStart{
        height: 30px !important;
    }
    .input-with-icon-textfield{
        height: 30px !important;
    }
    .reducedselect > .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
        height: 30px !important;
        padding: 0 !important;
    }
    .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
        height: 40px ;
        padding: 0 !important;
    }
   .btn-add-factura{
       color:blue !important;
   }
   .btn-remove-factura
   {
    color:rgb(212, 62, 69) !important;
   }
    /*
    .MuiOutlinedInput-root{
        height: 30px !important;
    }*/
    .modalform_label{
        text-align: right;
        padding-right: 10px;
        vertical-align: top;
        padding-top: 5px;  
        text-align: right;
        padding-right: 10px;
        vertical-align: top;
        font: 14px 'AvenirLTStd-Medium',Arial, Helvetica, sans-serif;
        color: rgb(26, 25, 25);
        padding-top: 5px;
    }
    .modalform_field{
        vertical-align: top;
        position: relative;
        margin: -21px 0 0 -8px;
        font-size: 13px;
        
    }
    .modalform_field > .form-control{
            /* border: 1px solid #b3b3b3; */
    border: 1px solid #ddd;
    background: #fff;
    padding: 6px 10px;
    outline: none;
    width: 205px;
    border-radius: 4px !important;
    
    }
    .modalform_field > .react-datepicker-wrapper> .react-datepicker__input-container > .form-control{
        /* border: 1px solid #b3b3b3; */
border: 1px solid #ddd;
background: #fff;
padding: 6px 10px;
outline: none;
height: 16;
width: 205px;
border-radius: 4px !important;
}
.modalform_field > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root {
            /* border: 1px solid #b3b3b3; */
border: 1px solid #ddd;
background: #fff;
padding: 6px 10px;
outline: none;
height: 16;
width: 205px;
border-radius: 4px !important;
}
.btn-success{
    background-color: #0f7dd4;
    color:#fff !important;
}
.btn-primary{
    background-color: #0f7dd4;
    color:#fff !important;
}
.btn-secondary{
    background-color: #f3f3f3;
    background: -webkit-linear-gradient(#FEFEFE, #f3f3f3);
    background: -o-linear-gradient(#FEFEFE, #f3f3f3);
    background: -moz-linear-gradient(#FEFEFE, #f3f3f3);
    text-align: center;
    background: linear-gradient(#FEFEFE, #f3f3f3);
    color:rgb(100, 98, 98) !important;
}

.btn{
    color: rgb(75, 74, 74);
    font: 500 13px 'AvenirLTStd-Book',Arial, Helvetica, sans-serif;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    border-radius: 25px;
    padding: 5px 10px;
    min-width: 90px;
    min-height: 28px;
    line-height: 18px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    vertical-align: bottom;
}
.MuiStepIcon-root.MuiStepIcon-active{
color: #0f7dd4 !important;
}
.MuiButton-containedPrimary {
    color: #fff;
    background-color: #0f7dd4 !important;
}
.roundBtn {
    background: none;
    border: 1px solid;
    color: var(--primary);
    color: #0f7dd4/*fallback*/;
    display: block;
    line-height: normal;
    position: relative;
    text-decoration: none;
    vertical-align: middle;
    border-radius: 25px;
    font-size: 13px;
    font-weight: 500;
    padding: 5px 10px;
    white-space: nowrap;
    display: inline-block;
    transition: none;
    text-align: center;
    min-width: 100px;
    min-height: 28px;
    min-width: 60px;
}

.modal-header{
    /*background-color: #0f7dd4;*/
    background-color: #d5e1eb
    

    
}
.modal-title{
    color: rgb(0, 0, 0);
}
.modal-header > .close{
    color: rgb(0, 0, 0);
}
.ExpenseTable2 {
    clear: both;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 6%);
    flex-basis: 0;
    flex-grow: 1;
    margin: 0px;
}

.ExpenseTable2 td.totalExpense, .totalExpense span {
    color: var(--primary) !important;
    color: #f26522;
}

.ExpenseTable2 td {
    /* color: #757575; */
    font-size: 14px;
    line-height: normal;
    padding: 0px 15px;
    border-right: 1px solid #ccc;
  //  font-family: 'AvenirLTStd-Roman';
}
.ExpenseTable2 td span {
    float: right;
    font-size: 18px;
    font-weight: normal;
    font-family:  'Lato', sans-serif;
    display: block;
    width: 100%;
    margin-top: 5px;
}
.ExpenseTable2 td.totalExpense, .totalExpense span {
    color: var(--primary) !important;
    color: #f26522;
}
.ExpenseTable2 td:nth-last-child(1) {
    border-right: none;
}
.vaT {
    vertical-align: top;
}
.taC {
    text-align: center;
}
.pRight0 {
    padding-right: 0 !important;
}
.pLeft0 {
    padding-left: 0px !important;
}
.red{
    color: red;
}
.card-header{
    background-color: rgba(0,0,0,0);
    padding: .03rem 1.25rem;
}
.card-body{
    height: auto !important;
}
.air__layout > .row> .col-12>h3{
    color: #fff;
}
.air__layout > .row{
    height:100%;
}
    
.air__layout > .row > .col-12 > .card {
    height:97%;
}
.MuiTabs-scroller{
    @media screen and (max-width: 1200px){
        overflow-x: scroll !important;
    }
}


.MuiTabs-scroller::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    background-color: #F5F5F5; }
    
    .MuiTabs-scroller::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #98e081; }

    
 .MuiTab-root {
    @media screen and (min-width: 600px){
        min-width: 80px !important;
        font-size: 10px !important;
    }
    @media screen and (min-width: 1200px){
        min-width: 100px !important;
        font-size: 11px !important;
    }
    @media screen and (min-width: 1500px){
        min-width: 160px !important;
        font-size: 13px !important;
    }

    
}

.BrainhubCarousel__arrows{
    background-color: #0f7dd4 !important;
}

.BrainhubCarousel__arrows:disabled {
    background-color: #ccc !important;
}
.BrainhubCarousel__arrows:hover:enabled {
    background-color: #0f7dd4 !important;
}
.BrainhubCarousel__arrows:hover {
    background-color: #0f7dd4 !important;
}
.react-daterange-picker__calendar{
    z-index: 200 !important;
}
.react-daterange-picker__wrapper{
    border: thin solid #ced4da !important;
    border-radius: 0.25 rem !important; 
}
.handsontable th {
    background-color: #f0f0f0;
    color: #222;
    text-align: center;
     font-weight: bold !important; 
    white-space: nowrap;
}
.recharts-legend-item-text{
    color: #36596b !important;
}