//@import url( system/style.css );
@import 'handsontable/dist/handsontable.full.css';
@import 'react-select/dist/react-select.css';

/* THEME STYLE */
@import 'variables';
@import 'colors';
@import 'layout_admin';
@import 'auth';

$sidebar-bg-color: #1d1d1d;
@import '~react-pro-sidebar/dist/scss/styles.scss';

html {
  height: 100%;
  font-size: 15px;
}

@media (max-width: 767px) {
  html {
    font-size: 14px;
  }
}

body {
  font-size: 1rem;
  line-height: 1.5;
  font-family: $font-family;
  overflow-x: hidden;
  position: relative;
  color: "#333441" !important;
  background: #0f7dd4;
  font-family: $font-family  !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family  !important;
}

a {
  text-decoration: none;
  -webkit-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.secondary {
  background: $accent-color-2 !important;
}

.font-size-30 {
  font-size: 30px;
}

/* TOP BAR - LOGO AND PHONE */
.info-contacto {
  margin-top: 2rem;
  font-weight: bold;
}

.react-datepicker-wrapper {
  width: 100%;
}

.MuiGrid-grid-xs-12>.react-datepicker-wrapper {
  width: 60%;
}

.MuiGrid-justify-xs-space-between>.react-datepicker-wrapper {
  width: 60%;
}

.departure-datepicker {
  width: 100%;
}

h1 {
  font-size: 2rem !important;
  line-height: 2rem;
}

h2 {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.noprint {
  @media print {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .departure-datepicker {
    margin-left: -150px;

    .react-datepicker__triangle {
      right: 5px !important;
      left: 0;
    }
  }
}

/* CONTENEDOR FLEX PARA LOS DATOS BASICOS DE TRANSPORTACION*/
.contenedor-flex {
  font-size: 16px;
  display: flex;
  flex-direction: row;
}

#breadcrumbs {
  height: 22px;
  background-color: $accent-color-2;
}

.info-bread {
  font-size: 1.3rem;
  color: white;
  padding: 5px 0;
  flex-grow: 1;
}

.select-search {
  width: 100% !important;
  background: #f1f1f1 !important;
}

.badge {
  font-size: 1rem !important;
  margin-right: 1rem;
  background: $accent-color-2 !important;
}

#top-bar {
  background: #fff;
  padding: 10px 0;

  li {
    a {
      color: $accent-color-2;
      font-size: 1.3rem;
    }
  }
}

#booking-confirm {
  background-color: #f2f2f2;
}

#booking-form {

  [type=color],
  [type=date],
  [type=datetime-local],
  [type=datetime],
  [type=email],
  [type=month],
  [type=number],
  [type=password],
  [type=search],
  [type=tel],
  [type=time],
  [type=url],
  [type=week],
  textarea,
  .input-text {
    background: #fff !important;
    border: 1px solid #efefef !important;
  }

  input:disabled,
  input[readonly],
  textarea:disabled,
  textarea[readonly] {
    background: #fff;
  }

  form {
    svg {
      margin-top: .7rem;
      margin-left: -.5rem;
    }
  }

  .arrival-information {
    border-right: 1px solid lightgray;
  }

  .booking-box select,
  .booking-box input {
    border: 1px solid #d9d9d9 !important;
    background: #fff;
  }

  .bg-icons {
    color: #fff;
    background: $accent-color-2;
    border: none;
  }

  #img {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

}

.card-panels {

  .panel {
    background-color: white;
    color: $accent-color-1;
    padding: 2rem;
    border-radius: 5px;
    border: 1px solid #e4e4e4;
    box-shadow: 12px 12px 32px 2px rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    position: relative;

    span {
      font-weight: $font-weight-light;
      font-size: 1.5rem;
      margin: .5rem 0;
    }

    .big-icon-dash {
      font-size: 5.5rem;
      position: absolute;
      color: rgba(3, 133, 0, .2);
      right: 5px;
      bottom: 0;
    }
  }

}


.heading-title-search {
  border-bottom: 1px solid lightgray;
}

.callout {
  border: none !important;
  border-radius: 4px;
}

.callout.alert {
  color: $accent-color-1;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
}

/*     formularios******************************* */

.input-group {
  flex-wrap: nowrap !important;
}

#formAction {
  background: #fff;
}

input:disabled,
input[readonly],
textarea:disabled,
textarea[readonly] {
  background: #fff;
  cursor: pointer !important;
}

.input-group-label,
.input-group-ArrCalendar,
.input-group-time,
.input-group-DeparCalendar {
  text-align: center;
  padding: 0 1rem;
  background: #e5e5e5;
  color: #0a0a0a;
  border: 1px solid #707070;
  white-space: nowrap;
  width: 1%;
  height: 2.4375rem;
}

.input-group-button,
.input-group-field,
.input-group-ArrCalendar,
.input-group-time,
.input-group-DeparCalendar {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle;
}

.input-group-label:last-child {
  border-left: 0;
}

.border-bottom {
  border-bottom: 1px solid $accent-color-3;
}


.info-bar {
  border-radius: 5px;
  margin: 4rem 0;
}

/*fin*/
/* alineacion en tablas */
.table {

  td,
  th {
    vertical-align: middle !important;
  }
}

/* carrucel home page*/
.carousel-caption h5 {
  font-weight: bold;
}

.details {
  border: 1px solid #f1f1f1;
  color: #000;
  background: #f0f0f0;
  color: #000;
  margin-bottom: 2rem;

  @media screen and (max-width:480px) {
    background: #f1f1f1;
  }
}

/**********************/
.datepickers-container {
  z-index: 99999;
}

.datepicker--time,
.datepicker--time-current-ampm {
  font-weight: 700;
  background: $accent-color-2;
  color: white !important;
}

.datepicker--time-current-hours:after,
.datepicker--time-current-minutes:after {
  background: $accent-color-3 !important;
}

/***********************/
.banner-cancellation-free {
  border: 2px solid green !important;
  padding: 20px;
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.css-yk16xz-control {
  background-color: white !important;
  border: none !important;
  border-radius: unset !important;
  height: 15px;
  min-height: 32px;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);

  .css-1hwfws3 {
    height: 10px !important;
  }

  .css-1hb7zxy-IndicatorsContainer {
    height: 30px !important;
  }

  .css-1okebmr-indicatorSeparator {
    border: none !important;
  }

  .css-1wa3eu0-placeholder {
    margin: 0 !important;
    padding: 0 !important;
  }

  .css-b8ldur-Input {
    input[type=text] {
      background: transparent !important;
      border: none !important;
    }
  }
}

.politicas {
  height: 150px;
  overflow: scroll;
}

.modal-footer {
  .button {
    margin-left: 1rem;
  }
}

/**   CAMBIOS PARA HACER EL MODAL SCROLLEABLE***/
/*.modal-body {
    max-height: 100vh;  
    overflow-y: auto;

  }*/
.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.3) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  outline: none;
  width: 50rem;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
  position: relative;
}

.excel-download-btn span button {
  position: relative;
  border: 1px solid transparent;
  border-radius: .25rem;
  color: white;
  background-color: green;
  border-color: #262626;
  width: 100%;
}

.low {
  color: #2980b9;
}

.equal {
  color: #f39c12;
}

.maxed {
  color: #c0392b;
}

.check-visualizar-areas-comunes {
  width: 3rem;
  margin-top: -.5rem;
}

.btn-m2 {
  float: right;
  padding: 2px 10px;
}

.air__menuLeft__link {
  margin: 1em;
}

#hot-display-license-info {
  display: none;
}



/* table */
/*
.handsontable .table th, .handsontable .table td {
    border-top: none;
  }
  
  .handsontable tr {
    background: #fff;
  }
  
  .handsontable td {
    background-color: inherit !important;
  }
  
  .table caption + thead tr:first-child th,
  .table caption + thead tr:first-child td,
  .table colgroup + thead tr:first-child th,
  .table colgroup + thead tr:first-child td,
  .table thead:first-child tr:first-child th,
  .table thead:first-child tr:first-child td {
    border-top: 1px solid #CCCCCC;
  }
  */
/* table-bordered */

/*
  .handsontable .table-bordered {
    border: 0;
    border-collapse: separate;
  }
  
  .handsontable .table-bordered th,
  .handsontable .table-bordered td {
    border-left: none;
  }
  
  .handsontable .table-bordered th:first-child,
  .handsontable .table-bordered td:first-child {
    border-left: 1px solid #CCCCCC;
  }
  
  .table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
    line-height: 21px;
    padding: 6px;
  }
  
  .col-lg-1.handsontable, .col-lg-10.handsontable, .col-lg-11.handsontable, .col-lg-12.handsontable,
  .col-lg-2.handsontable, .col-lg-3.handsontable, .col-lg-4.handsontable, .col-lg-5.handsontable, .col-lg-6.handsontable, .col-lg-7.handsontable, .col-lg-8.handsontable, .col-lg-9.handsontable,
  .col-md-1.handsontable, .col-md-10.handsontable, .col-md-11.handsontable, .col-md-12.handsontable,
  .col-md-2.handsontable, .col-md-3.handsontable, .col-md-4.handsontable, .col-md-5.handsontable, .col-md-6.handsontable, .col-md-7.handsontable, .col-md-8.handsontable, .col-md-9.handsontable
  .col-sm-1.handsontable, .col-sm-10.handsontable, .col-sm-11.handsontable, .col-sm-12.handsontable,
  .col-sm-2.handsontable, .col-sm-3.handsontable, .col-sm-4.handsontable, .col-sm-5.handsontable, .col-sm-6.handsontable, .col-sm-7.handsontable, .col-sm-8.handsontable, .col-sm-9.handsontable
  .col-xs-1.handsontable, .col-xs-10.handsontable, .col-xs-11.handsontable, .col-xs-12.handsontable,
  .col-xs-2.handsontable, .col-xs-3.handsontable, .col-xs-4.handsontable, .col-xs-5.handsontable, .col-xs-6.handsontable, .col-xs-7.handsontable, .col-xs-8.handsontable, .col-xs-9.handsontable {
    padding-left: 0;
    padding-right: 0;
  }

  .table-striped > tbody > tr:nth-of-type(even) {
    background-color: #FFF;
  }*/

.customdatepicker {
  text-transform: uppercase !important;
  text-align: center !important;
  height: 30px !important;
}

.react-datepicker__month-text {
  text-transform: uppercase !important;

}

.requiredlabel {
  content: "*";
  color: red;
  font-size: large !important;
}

.pro-sidebar {
  //height: calc(100vh - 2px); ;
  height: auto;
  // box-sizing: border-box;

}

.handsontable th,
.handsontable td {
  vertical-align: middle !important;
  padding: 4px 4px 4px 4px;
  line-height: 13px !important;
  //  font-size: 10px  !important;
}

/*  .handsontable thead th{
    font-size: 10px !important;
  }*/
.handsontable .htDimmed {
  color: #262626;
  //  font-size: 10px !important;
}

.currencyrecord {
  color: blue;
  text-align: center;
  display: block;
}

.currencyrecordnegativo {
  color: rgb(207, 72, 72);
  text-align: center;
  display: block;
}

.currencyrecordmin {
  color: blue;
}

.normalrecord {
  color: #262626;
  text-align: center;
  display: block;
}

.normalrecordleft {
  color: #262626;
  text-align: left;
  display: block;
}

.react-datepicker-popper {
  z-index: 200 !important;
}

.MuiAppBar-colorPrimary {
  color: black !important;
  background-color: white !important;
}

.MuiTabs-indicator {
  background-color: #2fa2fa !important;
  height: 4px !important;
}

#simple-tabpanel-0>.MuiBox-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.MuiGrid-spacing-xs-2>.MuiGrid-item {
  padding-left: 0 !important;
  padding-right: 16px !important;
}

.form-group>.react-datepicker-wrapper>.react-datepicker__input-container>input {
  width: 120px !important;
}


/**
 * Main wrapper
 */
.select-search {
  width: 300px;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2FCC8B;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2FCC8B;
}


.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.currentRowHightlight {
  background-color: #bae0f6 !important;
}

.smalladdbtn>span>span {
  margin-right: 0;
}

.labeladd {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 65%;
  font-weight: 700;
  line-height: 1;
  height: 17px;
  margin-top: 9px;
  color: rgb(39, 39, 39);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  cursor: pointer;
}

.labeladd-default {
  background-color: #d5d5d5;
}

.btnadd {
  background-color: rgb(189, 189, 190);
  border: none;
  color: rgb(0, 0, 0);
  padding: 3px 3px;
  height: 20px;
  margin-top: 7px;
  font-size: 0.62vw;
  cursor: pointer;
  border-radius: .25em;
}

/* Darker background on mouse-over */
.btnadd:hover {
  background-color: rgb(124, 124, 124);
}

.MuiAutocomplete-input {
  background-color: white !important;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
  overflow-y: hidden;
}

.MuiTableCell-root {
  padding: 0px !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #0f7dd4 !important;
}

.btnconbadge>.MuiBadge-root>.MuiSvgIcon-root {
  font-size: 1.1rem !important;
}

.btnconbadge>.MuiBadge-root>.MuiBadge-badge {
  transform: scale(0.7) translate(50%, -50%) !important;
}

.form-control>.react-daterange-picker__wrapper {
  border: none !important;
}

.dashboarditem-container-vertical-center {
  width: '100%';
  height: '100%';
  position: 'relative';
  text-align: 'center';
}

.dashboarditem-content-vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: 900;
  font-size: 20px;
  color: #2fa2fa
}
.dashboarditem-content-vertical-center-danger {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: 900;
  font-size: 20px;
  color: #ed7c88
}
.recharts-legend-item-text{
  font-weight: 900;
}
.recharts-cartesian-axis-tick-value{
  font-weight: 900;
  color: #36596b;

}
.BrainhubCarousel > * + * {
  margin-left:5px;
}